"use client"
import { useState, useEffect } from "react"
import { useTheme } from "next-themes"
import { IconSun, IconMoon } from '@/lib/reactIcon'

const DarkModeBtn = () => {
	const [mounted, setMounted] = useState(false)
	const { systemTheme, theme, setTheme } = useTheme()
	useEffect(() => setMounted(true), [])

	if (!mounted) return null

	const isDark = (theme === "system" ? systemTheme : theme) === "dark"

	return (
		<div onClick={() => setTheme(isDark ? "light" : "dark")} className="h-4 w-4 cursor-pointer">
			{
				isDark ?
					(<IconSun className="text-yellow-400" />)
					:
					(<IconMoon className="text-slate-700" />)
			}
		</div>
	)
}
export default DarkModeBtn
