import { VscTwitter } from "react-icons/vsc"
import { PiInstagramLogoDuotone, PiMoonStarsFill, PiSunFill } from "react-icons/pi"
import { AiOutlineWeibo } from "react-icons/ai"
import { TbBrandReact, TbBrandThreejs, TbBrandVue } from "react-icons/tb"
import { BsTelegram } from "react-icons/bs"
import { AiFillMail } from "react-icons/ai"
import { VscNotebook, VscGithubInverted, VscRss, VscFilePdf } from 'react-icons/vsc'


// header icons
export const IconNotes = VscNotebook
export const IconGithub = VscGithubInverted
export const IconRSS = VscRss
export const IconPDF = VscFilePdf

// about page icons
export const IconTwitter = VscTwitter
export const IconInstagram = PiInstagramLogoDuotone
export const IconTelegram = BsTelegram
export const IconWeibo = AiOutlineWeibo
export const IconReact = TbBrandReact
export const IconThreejs = TbBrandThreejs
export const IconVue = TbBrandVue
export const IconMail = AiFillMail

// theme switcher icons
export const IconMoon = PiMoonStarsFill
export const IconSun = PiSunFill
