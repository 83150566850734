"use client"
import { ThemeProvider } from "next-themes"

export const Providers = ({ children }: { children: React.ReactNode }) => {
	return (
		<ThemeProvider enableSystem={true} defaultTheme="system" attribute="class">
			{children}
		</ThemeProvider>
	)
}
